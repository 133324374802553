/* global $ ga */
var clicked = false

$('[data-verify-outbound]').on('click', function (e) {
  var $link = $(this)

  // Don't follow the link, we hold people back until the analytics event has successfully fired
  e.preventDefault()

  // Create helper method to follow the link once we're done
  function onwards () {
    window.location = $link.attr('href')
  }

  // Only fire the analytics event the first time someone clicks the button to circumvent double clickers
  if (!clicked) {
    // If google analytics is slow, just bail out after 5 seconds and navigate the user onwards
    var timeout = setTimeout(function () {
      onwards()
    }, 5000)

    // Send the pageview to analytics
    // Once google analytics reports that it has received the pageview, we navigate te user onwards
    if (analytics_cookie.categories.analytics == "on"){
      ga('send', 'pageview', '/verify', {
        title: 'Verify',
        hitCallback: function () {
          clearTimeout(timeout)
          onwards()
        }
      })
    } else {
      onwards()
    }
  }

  clicked = true
})
